import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { SeoServicesComponent } from './seo-services/seo-services.component';
import { WebsiteDevelopmentComponent } from './website-development/website-development.component';
import { SocialMediaMarketingComponent } from './social-media-marketing/social-media-marketing.component';
import {ContactUsComponent} from './contact-us/contact-us.component';
import { AiChatbotsComponent } from './ai-chatbots/ai-chatbots.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'index.html', component: HomeComponent },
  { path: 'seo-services', component: SeoServicesComponent }, 
  { path: 'seo-services.html', component: SeoServicesComponent }, 
  { path: 'website-development.html', component: WebsiteDevelopmentComponent }, 
  { path: 'website-development', component: WebsiteDevelopmentComponent }, 
  { path: 'social-media-marketing', component: SocialMediaMarketingComponent }, 
  { path: 'social-media-marketing.html', component: SocialMediaMarketingComponent },  
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'contact.html', component: ContactUsComponent },
  { path: 'ai-chatbot.html', component: AiChatbotsComponent },
  { path: 'ai-chatbots', component: AiChatbotsComponent },
  { path: 'privacypolicy', component: PrivacypolicyComponent },
  { path: 'privacypolicy.html', component: PrivacypolicyComponent },

  
  { path: '**', redirectTo: '', pathMatch: 'full' }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
