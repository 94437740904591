    <!-- Hero Section -->
    <section id="home" class="hero">
        <div class="hero-content">
            <h1 class="hero-heading">Your Marketing Solutions Partner</h1>
            <p class="hero-description">Helping businesses grow with expert SEO services, professional website development, and impactful social media marketing.</p>
            <a href="/contact-us" target="_blank" class="cta-button">Contact Us for Marketing Solutions</a>
        </div>
    </section>

    <!-- About Section -->
    <section id="about" class="about-section">
        <div class="container">
            <h2>About Us</h2>
            <p>At DreamMenders, we are passionate about delivering top-notch marketing solutions tailored to your business needs. With years of experience, our team of experts is dedicated to helping you achieve your marketing goals and drive growth.</p>
        </div>
    </section>

    <!-- Services Section -->
    <section id="services" class="services-section">
        <div class="container">
            <h2>Our Services</h2>
            <div class="services-container">
                <!-- Website Development & Hosting -->
                <div class="service-item">
                    <i class="fas fa-globe"></i> <!-- Icon for Website Development -->
                    <h3>Website Development & Hosting</h3>
                    <p>We build high-performing, SEO-optimized websites with secure hosting, designed to drive traffic and conversions.</p>
                    <a href="/website-development" class="learn-more-button">Learn More</a>
                </div>
                <!-- SEO -->
                <div class="service-item">
                    <i class="fas fa-search"></i> <!-- Icon for SEO -->
                    <h3>Search Engine Optimization (SEO)</h3>
                    <p>Boost your online presence with our expert SEO services. We optimize your site to rank higher and drive more traffic.</p>
                    <a href="/seo-services" class="learn-more-button">Learn More</a>
                </div>
                <!-- Social Media Marketing -->
                <div class="service-item">
                    <i class="fas fa-bullhorn"></i> <!-- Icon for Social Media Marketing -->
                    <h3>Social Media Marketing</h3>
                    <p>Engage your audience with targeted social media campaigns. We help grow your brand on platforms like Facebook and Instagram.</p>
                    <a href="/social-media-marketing" class="learn-more-button">Learn More</a>
                </div>
                <!-- AI Chatbots & Automations -->
                <div class="service-item">
                    <i class="fas fa-robot"></i> <!-- Icon for AI Chatbots -->
                    <h3>Custom AI Chatbot Agents & Automation</h3>
                    <p>Transform your business with AI-powered chatbots and workflow automation. Improve customer service and streamline operations.</p>
                    <a href="/ai-chatbots" class="learn-more-button">Learn More</a>
                </div>
            </div>
        </div>
    </section>

    <!-- Clients Section -->
    <section id="clients" class="clients-section">
        <div class="container">
            <h2>Our Clients</h2>
            <p>We are proud to work with a range of clients across various industries. Below is a list of some of our clients:</p>
            <div class="clients-container">
                
                <!-- Placeholder for future clients -->
                <div class="client-item">
                    <img src="./client-logo.png" alt="Future Client Logo" class="client-logo" loading="lazy">
                </div>
          
            </div>
        </div>
    </section>

    <i class="fas fa-globe"></i> 