import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { SeoServicesComponent } from './seo-services/seo-services.component';
import { WebsiteDevelopmentComponent } from './website-development/website-development.component';
import { SocialMediaMarketingComponent } from './social-media-marketing/social-media-marketing.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AiChatbotsComponent } from './ai-chatbots/ai-chatbots.component';
import { ReactiveFormsModule } from '@angular/forms';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component'; // Use provideHttpClient

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SeoServicesComponent,
    WebsiteDevelopmentComponent,
    SocialMediaMarketingComponent,
    ContactUsComponent,
    AiChatbotsComponent,
    PrivacypolicyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule  ],
  providers: [
    provideClientHydration(),
    provideHttpClient(withFetch())

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
